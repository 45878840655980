/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:1111dfaa-f6ab-4f31-bdb8-1f643b677941",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_FHHsXiUIT",
    "aws_user_pools_web_client_id": "6gtk2i8hjrh60crqnt7ot279jl",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://igba2h4as5e5vlxw4pxoshgojq.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "mettlCallback",
            "endpoint": "https://j11rib9vm9.execute-api.ap-south-1.amazonaws.com/usermig",
            "region": "ap-south-1"
        },
        {
            "name": "instamojoCallback",
            "endpoint": "https://77jqrgsysh.execute-api.ap-south-1.amazonaws.com/usermig",
            "region": "ap-south-1"
        }
    ],
    "aws_user_files_s3_bucket": "admissionsbackendstoragebucket81812-usermig",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;
